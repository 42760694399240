import React from "react";
import { Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import route from "../data/routes.json";

const MobileSideMenu = () => {
  return (
    <div>
      <Menu
        menuItemStyles={{
          button: {
            ":hover": {
              backgroundColor: "grey",
              color: "#fff",
            },
            ":focus": {
              backgroundColor: "grey",
              color: "#fff",
            },
          },
        }}
      >
        {route
          .filter((item) => item.show)
          .map((item) => {
            if (item.child) {
              return (
                <SubMenu label={item.name} key={item}>
                  {item.child.map((childItem) => (
                    <MenuItem
                      key={childItem.id}
                      href={childItem.path}
                      style={{
                        background: "#660000",

                        outline: "1px solid grey",
                        ":hover": {
                          backgroundColor: "red",
                        },
                      }}
                    >
                      {childItem.name}
                    </MenuItem>
                  ))}
                </SubMenu>
              );
            } else {
              return (
                <MenuItem key={item.id} href={item.path}>
                  {item.name}
                </MenuItem>
              );
            }
          })}
      </Menu>
    </div>
  );
};

export default MobileSideMenu;
