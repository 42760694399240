import React, { useState } from "react";
import { Link } from "react-router-dom";
import route from "../data/routes.json";
import { Menu, MenuItem, Avatar, Divider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import { GiHamburgerMenu } from "react-icons/gi";
import MobileSideMenu from "./MobileSideMenu";
function Navbar() {
  const user = JSON.parse(localStorage.getItem("driver"));

  const [showServicesMenu, setShowServicesMenu] = useState(false);
  const [openBookingMenu, setOpenBookingMenu] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl1, setAnchorEl1] = React.useState(null);

  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const openBooking = Boolean(anchorEl1);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickUserAccount = (eve) => {
    setAnchorEl1(eve.currentTarget);
  };
  const theme = createTheme({
    overrides: {
      MuiMenu: {
        paper: {
          backgroundColor: "#66000",
        },
      },
    },
  });
  const handleClose = () => {
    setAnchorEl(null);
    setAnchorEl1(null);
  };

  const style = {
    background: "white",
    color: "black",
  };
  const style2 = {
    background: "#660000",
    color: "white",
  };
  const handleLogout = () => {
    localStorage.removeItem("driver");
    navigate("/");
  };

  const handleSiderBar = () => {
    setIsOpen(!isOpen);
  };
  const handleMouseEnter = (e) => {
    if (e.target.innerText === "Services") {
      setShowServicesMenu(!showServicesMenu);
    }
  };
  return (
    <>
      <nav className="nav_bar">
        <div className="container">
          <div>
            <img
              src="/../spaceheroLogo.png"
              width={160}
              height={50}
              alt="main_logo"
            />
          </div>
          <div className="Desktop_view">
            <div>
              <ul className="navText">
                {route
                  .filter((item) => item.show)
                  .map((item, ind) => (
                    <li key={ind} onMouseEnter={(e) => handleMouseEnter(e)}>
                      {item.child ? (
                        <>
                          <Link
                            to={""}
                            style={showServicesMenu ? style : style2}
                          >
                            {item.name}
                            <span
                              className={
                                showServicesMenu
                                  ? "show_service"
                                  : "hide_service"
                              }
                            >
                              <IoIosArrowDown />{" "}
                            </span>
                          </Link>

                          <Menu
                            id="demo-positioned-menu"
                            PaperProps={{
                              elevation: 0,
                              sx: {
                                overflow: "visible",
                                filter:
                                  "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                ml: -11,
                                background: "#660000",
                                width: "23vh",

                                "&::before": {
                                  content: '""',
                                  display: "block",
                                  position: "absolute",

                                  bgcolor: "#660000",
                                  transform: "translateY(-50%) rotate(45deg)",
                                  zIndex: 0,
                                },
                              },
                            }}
                            open={showServicesMenu}
                            aria-labelledby="demo-positioned-button"
                            anchorReference="anchorPosition"
                            anchorPosition={{ top: 60, left: 1020 }}
                            onClose={() => setShowServicesMenu(false)}
                          >
                            {item.child.map((child, i) => {
                              return (
                                <div key={i} tabIndex={"1234"}>
                                  <MenuItem
                                    sx={{
                                      color: "white",
                                      "&:hover": {
                                        backgroundColor: "white",
                                        color: "black",
                                      },
                                    }}
                                    className="services_MenuItem"
                                  >
                                    <Link
                                      to={child.path}
                                      style={{
                                        textDecoration: "none",
                                        color: "inherit",
                                      }}
                                    >
                                      {child.name}
                                    </Link>
                                  </MenuItem>
                                  <div className="custom_divider"></div>
                                </div>
                              );
                            })}
                          </Menu>
                        </>
                      ) : (
                        <>
                          <Link to={item.path}>{item.name}</Link>
                        </>
                      )}
                    </li>
                  ))}
              </ul>
            </div>
            <div style={{ paddingInlineStart: "2vh" }}>
              {user ? (
                <>
                  <Avatar
                    id="basc"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                    sx={{
                      background: "white",
                      color: "black",
                      height: "5vh",
                      width: "5vh",
                      "&:hover": {
                        background: "gray",
                        cursor: "pointer",
                      },
                    }}
                  >
                    {user.name.split("")[0].toUpperCase()}
                  </Avatar>
                  <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    id="mobile"
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",

                        background: "#660000",
                        color: "white",
                        width: "20vh",

                        "& .MuiAvatar-root": {
                          width: 132,
                          height: 2,
                        },

                        "&::before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "#660000",
                          transform: "translateY(-50%) rotate(45deg)", // change from translateY(-50%) to translateY(50%)
                          zIndex: 0,
                        },
                      },
                    }}
                    anchorOrigin={{
                      horizontal: "right",
                      vertical: "bottom",
                    }}
                    transformOrigin={{
                      horizontal: "right",
                      vertical: "top",
                    }}
                    classes={{
                      paper: theme.overrides.MuiMenu.paper,
                    }}
                  >
                    <MenuItem
                      sx={{
                        "&:hover": {
                          backgroundColor: "white",
                          color: "black",
                        },
                      }}
                    >
                      {user?.name}
                    </MenuItem>
                    <Divider color={"white"} />

                    <MenuItem
                      sx={{
                        "&:hover": {
                          backgroundColor: "white",
                          color: "black",
                        },
                      }}
                      onClick={() => {
                        setOpenBookingMenu(true);
                      }}
                    >
                      Bookings
                    </MenuItem>
                    {openBookingMenu && (
                      <Menu
                        id="booking-menu"
                        anchorEl={anchorEl}
                        open={openBookingMenu}
                        onClose={() => setOpenBookingMenu(false)}
                        MenuListProps={{
                          "aria-labelledby": "booking-button",
                        }}
                        PaperProps={{
                          elevation: 0,
                          sx: {
                            overflow: "visible",

                            mt: 10.5,
                            ml: -19,
                            background: "#660000",
                            color: "white",
                            width: "25vh",
                          },
                        }}
                        transformOrigin={{
                          horizontal: "right",
                          vertical: "top",
                        }}
                        anchorOrigin={{
                          horizontal: "right",
                          vertical: "top",
                        }}
                      >
                        <MenuItem
                          sx={{
                            "&:hover": {
                              backgroundColor: "white",
                              color: "black",
                            },
                          }}
                          onClick={() => {
                            navigate("/booking/current");
                            setOpenBookingMenu(false);
                            handleClose();
                          }}
                        >
                          Current/Upcoming
                        </MenuItem>
                        <MenuItem
                          sx={{
                            "&:hover": {
                              backgroundColor: "white",
                              color: "black",
                            },
                          }}
                          onClick={() => {
                            navigate("/booking/history");
                            setOpenBookingMenu(false);
                            handleClose();
                          }}
                        >
                          History
                        </MenuItem>
                      </Menu>
                    )}

                    <MenuItem
                      sx={{
                        "&:hover": {
                          backgroundColor: "white",
                          color: "black",
                        },
                      }}
                      onClick={handleLogout}
                    >
                      Logout
                    </MenuItem>
                  </Menu>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className={`Mobile_view ${isOpen ? "show" : "hide"}`}>
            <GiHamburgerMenu color={"white"} onClick={handleSiderBar} />

            <div className={`sidebar ${isOpen ? "show" : "hide"}`}>
              <div>
                <div>
                  <img
                    src="/../spaceheroLogo.png"
                    height={50}
                    alt="main_logo"
                    style={{ width: "max-content" }}
                  />
                </div>
                <div
                  style={{
                    paddingTop: "3rem",
                  }}
                >
                  <MobileSideMenu />
                </div>

                <div
                  style={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    width: "100%",
                    padding: "2vh",
                    borderTop: "1px solid #fff",
                  }}
                >
                  {user ? (
                    <>
                      <div
                        id="basic-button"
                        aria-controls={openBooking ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={openBooking ? "true" : undefined}
                        onClick={handleClickUserAccount}
                        style={{
                          background: "white",
                          color: "black",
                          height: "6vh",
                          width: "100%",
                          boxShadow: "2px 2px 2px 2px !important",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {user.name}
                      </div>
                      <Menu
                        anchorEl={anchorEl1}
                        open={openBooking}
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                        id="mobile"
                        PaperProps={{
                          elevation: 0,
                          sx: {
                            overflow: "visible",
                            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",

                            background: "#660000",
                            color: "white",
                            width: "30vh",
                            ml: 1,
                            "& .MuiAvatar-root": {
                              width: 132,
                              height: 2,
                            },

                            "&::before": {
                              content: '""',
                              display: "block",
                              position: "absolute",
                              bottom: 20, // change from top: 0 to bottom: 0
                              left: 0, // change from right: 14 to left: 14
                              width: 18,
                              height: 30,
                              bgcolor: "#660000",
                              transform: "translateY(50%) rotate(45deg)", // change from translateY(-50%) to translateY(50%)
                              zIndex: 0,
                            },
                          },
                        }}
                        anchorOrigin={{
                          horizontal: "right",
                          vertical: "bottom",
                        }}
                        classes={{
                          paper: theme.overrides.MuiMenu.paper,
                        }}
                      >
                        <MenuItem
                          sx={{
                            "&:hover": {
                              backgroundColor: "white",
                              color: "black",
                            },
                          }}
                        >
                          {user?.name}
                        </MenuItem>
                        <Divider color={"white"} />

                        <MenuItem
                          sx={{
                            "&:hover": {
                              backgroundColor: "white",
                              color: "black",
                            },
                          }}
                          onClick={() => {
                            setOpenBookingMenu(true);
                          }}
                        >
                          Bookings
                        </MenuItem>
                        {openBookingMenu && (
                          <Menu
                            id="booking-menu"
                            anchorEl={anchorEl}
                            open={openBookingMenu}
                            onClose={() => setOpenBookingMenu(false)}
                            MenuListProps={{
                              "aria-labelledby": "booking-button",
                            }}
                            PaperProps={{
                              elevation: 0,
                              sx: {
                                overflow: "visible",

                                mt: 40,
                                ml: -14,
                                background: "#660000",
                                color: "white",
                                width: "30vh",
                              },
                            }}
                            transformOrigin={{
                              horizontal: "right",
                              vertical: "top",
                            }}
                            anchorOrigin={{
                              horizontal: "right",
                              vertical: "top",
                            }}
                          >
                            <MenuItem
                              sx={{
                                "&:hover": {
                                  backgroundColor: "white",
                                  color: "black",
                                },
                              }}
                              onClick={() => {
                                navigate("/booking/current");
                                setOpenBookingMenu(false);
                                handleClose();
                              }}
                            >
                              Current/Upcoming
                            </MenuItem>
                            <MenuItem
                              sx={{
                                "&:hover": {
                                  backgroundColor: "white",
                                  color: "black",
                                },
                              }}
                              onClick={() => {
                                navigate("/booking/history");
                                setOpenBookingMenu(false);
                                handleClose();
                              }}
                            >
                              History
                            </MenuItem>
                          </Menu>
                        )}

                        <MenuItem
                          sx={{
                            "&:hover": {
                              backgroundColor: "white",
                              color: "black",
                            },
                          }}
                          onClick={handleLogout}
                        >
                          Logout
                        </MenuItem>
                      </Menu>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
