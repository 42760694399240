import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  Modal: "",
  ModalData: {},
  isCalendar: false,
};
const bookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {
    addBooking: (state, action) => {
      state.booking = action.payload;
    },
    showModel: (state, action) => {
      state.Modal = action.payload;
    },
    AddModalData: (state, action) => {
      state.ModalData = action.payload;
    },
    showCalendor: (state, action) => {
      state.isCalendar = action.payload;
    },
  },
});

export const { addBooking, showModel, showCalendor, AddModalData } =
  bookingSlice.actions;
export default bookingSlice.reducer;
