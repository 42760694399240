import React from "react";
import { RouterProvider } from "react-router-dom";
import { router } from "./components/RouteComponent.js";
import store from "./Redux/store.js";
import { Provider } from "react-redux";
import { DriveMePageState } from "./context/bookingContext.js";
import { BookingResponseState } from "./context/bookingContext.js";
import { ConfirmBookResponse } from "./context/bookingContext.js";

import CustomModel from "./utils/Model.js";

import "./style/main.scss";
function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <DriveMePageState>
          <BookingResponseState>
            <ConfirmBookResponse>
              <RouterProvider router={router} />

              <CustomModel />
            </ConfirmBookResponse>
          </BookingResponseState>
        </DriveMePageState>
      </Provider>
    </div>
  );
}

export default App;
