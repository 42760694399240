import React, { createContext, useState } from "react";

export const MyDriveMePage = createContext();
export const MyBookingContext = createContext();
export const MyConfirmContext = createContext();

export const DriveMePageState = ({ children }) => {
  const [myDrivePage, setMyDrivePage] = useState("bookingPage");

  return (
    <MyDriveMePage.Provider value={{ myDrivePage, setMyDrivePage }}>
      {children}
    </MyDriveMePage.Provider>
  );
};

export const BookingResponseState = ({ children }) => {
  const [myValue, setMyValue] = useState(null);

  return (
    <MyBookingContext.Provider value={{ myValue, setMyValue }}>
      {children}
    </MyBookingContext.Provider>
  );
};

export const ConfirmBookResponse = ({ children }) => {
  const [bookResponse, setBookResponse] = useState(null);

  return (
    <MyConfirmContext.Provider value={{ bookResponse, setBookResponse }}>
      {children}
    </MyConfirmContext.Provider>
  );
};
