import React, { useState, useMemo, useEffect, useContext } from "react";
import { MyConfirmContext, MyDriveMePage } from "../context/bookingContext";
import { CgDanger } from "react-icons/cg";
import {
  Button,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { showModel } from "../Redux/bookingSlice";
import services from "../services/apiRoutes";

export default function CustomModel() {
  const user = JSON.parse(localStorage.getItem("driver"));

  const { bookResponse } = useContext(MyConfirmContext);
  const { setMyDrivePage } = useContext(MyDriveMePage);
  const [open, setOpen] = useState(false);
  const [containerHeight, setHeight] = useState("204px");
  const [content, setContent] = useState({
    Heading: "",
    body: "",
    forward: "",
    backward: "",
    button1style: "",
    button2style: "",
  });
  const heading = useSelector((state) => state.bookDriver.Modal);
  const ModalContent = useSelector((state) => state.bookDriver.ModalData);

  const data = useMemo(() => {
    switch (heading) {
      case "CancelRide":
        setHeight("400px");
        setContent({
          Heading: (
            <Grid display={"flex"}>
              <Grid>
                <CgDanger color={"white"} />
              </Grid>
              <Grid display={"flex"} paddingInlineStart={"90px"}>
                Cancel Driver Request
              </Grid>
            </Grid>
          ),
          body: (
            <Typography variant="h5" color="black">
              Your Request is in process We just need a moment
            </Typography>
          ),
          forward: "Yes,I want to cancel",
          backward: "Go Back",
        });
        return true;
      case "Price Estimation":
        setHeight("200px");
        setContent({
          Heading: "",
          body: (
            <Grid container flexDirection={"column"}>
              <Grid
                item
                xs={12}
                display={"flex"}
                justifyContent={"center"}
                p={2}
              >
                <Typography
                  variant="h4"
                  sx={{ color: "#660000", fontWeight: "600" }}
                >
                  Price Estimation
                </Typography>
              </Grid>
              <Grid
                display={"flex"}
                flexDirection={"column"}
                alignContent={"center"}
                justifyContent={"center"}
              >
                <Typography textAlign={"center"}>
                  Exact billing will be give to you when the service is
                  completed
                </Typography>
                <Typography textAlign={"center"}>
                  The price estimation given is solely based on the options{" "}
                  chosen by you and base price estimation for the smae
                </Typography>
              </Grid>
              <Divider color="black" />
              <Grid
                p={2}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
              >
                <Grid>
                  <Typography className="price_class">Base Price :</Typography>
                  <Typography className="price_class">Way Charge :</Typography>
                  <Typography className="price_class">GST% :</Typography>
                  <Typography
                    className="price_class"
                    style={{ color: "green" }}
                  >
                    Coupon Code :
                  </Typography>
                  <Typography className="price_class">Ride Fare :</Typography>
                </Grid>
                <Grid>
                  <Typography>{ModalContent.basePrice}</Typography>
                  <Typography>{ModalContent.wayCharge}</Typography>
                  <Typography>{ModalContent.tax}</Typography>
                  <Typography color="green">{ModalContent.coupon}</Typography>
                  <Typography>{ModalContent.parkFee}</Typography>
                </Grid>
              </Grid>
            </Grid>
          ),
          backward: "Dismiss",
          button1style: {
            display: "flex",
            outline: "1px solid grey",
            background: "#660000",
            color: "white",
            ":hover": {
              display: "flex",
              outline: "1px solid grey",
              background: "#660000",
              color: "white",
            },
          },
        });
        return true;
      default:
        return false;
    }
  }, [heading]);
  const dispatch = useDispatch();

  const handleClose = (e) => {
    const { id } = e.target;

    if (id === "1") {
      dispatch(showModel({ isModel: false }));
    } else if (id === "2" && heading === "CancelRide") {
      services.Booking.CancelRide({
        userId: user.userId,
        orderId: bookResponse.orderId,
      }).then(() => {
        setMyDrivePage("bookingPage");
      });
    }

    dispatch(showModel(false));
  };

  useEffect(() => {
    if (data === false) {
      setContent({
        Heading: "",
        body: "",
        forward: "",
        backward: "",
      });
      setOpen(data);
    } else {
      setOpen(data);
    }
  }, [data]);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        boxShadow: "12px 12px 12px 12px brown",
      }}
    >
      <DialogTitle
        id="alert-dialog-title"
        style={{ color: "white", background: "#660000" }}
      >
        {content.Heading}
      </DialogTitle>
      <DialogContent sx={{ width: "500px", height: containerHeight }}>
        <DialogContentText
          id="alert-dialog-description"
          sx={{ flexWrap: "nowrap" }}
        >
          {content.body}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          id={1}
          onClick={handleClose}
          sx={{ border: "1px solid white", color: "white" }}
        >
          {content.backward}
        </Button>
        {heading !== "Price Estimation" && (
          <Button
            id={2}
            onClick={handleClose}
            sx={{ border: "1px solid white", color: "white" }}
            autoFocus
          >
            {content.forward}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
