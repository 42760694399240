import { Suspense, lazy } from "react";
import { Box, Grid } from "@mui/material";

import { createBrowserRouter } from "react-router-dom";
import Navbar from "./Navbar";
import path from "../data/routes.json";

const AppLayout = ({ children }) => {
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateRows: "auto 2fr",
        minHeight: "100vh",
      }}
    >
      <Box sx={{ gridRow: 1, backgroundColor: "primary.main" }}>
        <Navbar />
      </Box>

      <Grid container sx={{ gridRow: 2, overflowY: "auto" }}>
        <Grid item xs={12} md={12} lg={12}>
          <Suspense fallback={<div>Loading...</div>}>{children}</Suspense>
        </Grid>
      </Grid>
    </Box>
  );
};

const routes = path.map((route) => {
  const Component = lazy(() => import(`../components/${route.component}`));
  const routeObject = {
    key: route.path,
    path: route.path,
    element: (
      <AppLayout>
        <Component />
      </AppLayout>
    ),
  };

  return routeObject;
});

export const router = createBrowserRouter(routes);
