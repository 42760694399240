import axios from "axios";
import { toast } from "react-toastify";

const url = process.env.REACT_APP_LOGIN_API_URL;

const makeRequest = async (apiUrl, data, successMessage) => {
  try {
    const response = await axios.post(`${url}/${apiUrl}`, data);

    if (response.data.Status === true) {
      response.data.Message !== "Coupon list" &&
        toast.success(response.data.Message);
      return response;
    } else if (response.data.Status === false) {
      toast.error(response.data.Message);
    } else {
      toast.error(
        "An error occurred while retrieving data. Please try again later."
      );
    }
  } catch (error) {
    toast.error(
      "An error occurred while retrieving data. Please try again later."
    );
    throw error;
  }
};

const services = {
  Auth: {
    Login: (num) => {
      return makeRequest("login", { phone: num });
    },
    submitOtp: (data) => {
      return makeRequest("loginotp", data);
    },
    AddProfile: (data) => {
      return makeRequest("addprofile", data);
    },
  },
  Booking: {
    BookRide: (data) => {
      console.log("this", data);
      return makeRequest("adddriveorder", data);
    },
    getCoupons: (data) => {
      return makeRequest("couponlist", { userId: data });
    },
    ConfirmBook: (data) => {
      return makeRequest("bookdriver", data);
    },
    CancelRide: (data) => {
      return makeRequest("cancelDriveOrder", data);
    },
    DriverDetails: (data) => {
      return makeRequest("userDriveOrderDetail", data);
    },
    BookingOrderHistorys: (data) => {
      return makeRequest("userOrderHistory", { userId: data });
    },
    currentBooking: (data) => {
      return makeRequest("userDriveHome", { userId: data });
    },
  },
  HireUsServices: {
    getHireUsServices: (data) => {
      return makeRequest("servicelist", { userId: data });
    },
  },
};
export default services;
